.exclusive-material {
  position: fixed;
  left: 5rem;
  bottom: 4%;
  transform: translateX(-35%) translateY(55%) rotate(-30deg) scale(1.3);
  opacity: 0;
  z-index: 2;
  filter: drop-shadow(-8px 8px 11px #00000042);

  &--show {
    animation: passport 1s 1 0.5s forwards;
  }
  &--hidden {
    display: none;
  }
  &__link {
    display: inline-block;
    transition: all 0.8s;
    color: black;
    &:hover {
      transform: rotate(-5.2deg) scale(1.1);
      filter: drop-shadow(-8px 8px 11px #00000093);
    }
  }
  &__image {
    width: 20vw;
  }
  &__text {
    position: absolute;
    bottom: 40%;
    right: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4vw;
    font-family: "Shadow";
    color: #000000;
    font-weight: 400;
    text-shadow: 0px 0px 0px #000000;
    transform: rotate(-13deg);
  }
}

@media (max-width: 820px) {
  .exclusive-material {
    left: -3rem;

    &__image {
      width: 35vw;
    }
    &__text {
      font-size: 2.4vw;
    }
  }
}

@media (max-width: 600px) {
  .exclusive-material {
    left: -5rem;
    bottom: 12%;

    &__image {
      width: 42vw;
    }
    &__text {
      font-size: 2.8vw;
    }
  }
}
