.c2024 {
  height: 80vh;
  position: relative;
  display: inline-block;
  transform: rotate(-8deg) translate(-7rem, -7rem);
  opacity: 0;
  &--animate {
    animation: in2024 1s 1 forwards;
  }

  &__image {
    max-height: 100%;
    width: auto;
    max-width: 100%;
    filter: drop-shadow(-9px -3px 10px #00000086);
  }
  &__calendar {
    position: absolute;
    width: 75%;
    top: 30%;
    left: 30%;
    transform: translateX(-30%);
    button {
      color: black;
    }
  }
}
@media (max-width: 820px) {
  .c2024 {
    height: auto;
    width: 85%;
    &__calendar {
      top: 27%;
    }
  }
}
@media (max-width: 600px) {
  .c2024 {
    &__image {
      width: 100%;
    }
    &__calendar {
      top: 25%;
      width: 70%;
    }
  }
}
@keyframes in2024 {
  30% {
    opacity: 1;
  }
  to {
    transform: rotate(-2deg) translate(0rem, 0rem);
    opacity: 1;
  }
}
