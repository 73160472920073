.back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 2;
  font-family: Shadow;
  font-size: 1.6rem;
  opacity: 0;
  transform: translateY(-50%);
  animation: translateY-0 1s 1 forwards;
  // border: 1px solid aqua;
  width: 7rem;
  &__image {
    width: 100%;
    transition: all 0.4s;

    &:hover {
      transform: translateX(-0.3rem) scale(1.03);
      filter: drop-shadow(11px 11px 11px #0000007e);
    }
  }
}
@media (max-width: 600px) {
  .back-btn {
    width: 10rem;
  }
}
