.next-dates-card {
  &__image-wrapper {
    width: 15rem;
    height: 28rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transform: translateY(-210%);

    &--animate {
      animation: translateY-0 1.5s 1 0.5s forwards;
    }

    &--animate-translateY-50 {
      animation: translateY-50 1.5s forwards;
    }

    &--inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: relative;
      transition: transform 1s;
      transform-style: preserve-3d;
      transform: perspective(30rem) rotateY(180deg);

      // border: solid 1px aqua;
    }

    &--front,
    &--back {
      position: absolute;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }
    &--front {
      transform: perspective(30rem) rotateY(0deg);
    }
    &--back {
      transform: perspective(30rem) rotateY(180deg);
    }
  }
  &__image-data-container {
    position: relative;
    width: 15rem;
    height: 25rem;
    // border: solid 1px aqua;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: solid 1px red;
  }
  &__image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 103%;
    z-index: -1;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.43));
  }
  &__image-wrapper--inner.flip {
    transform: perspective(30rem) rotateY(360deg);
  }
  &__data-wrapper-front {
    text-align: center;
    width: 80%;
    position: absolute;

    &__location {
      font-family: "Teko";
      font-weight: 400;
      font-size: 2.2rem;
      text-transform: uppercase;
      line-height: 105%;
      margin-bottom: 0.5rem;
    }
    &__date {
      font-family: "Teko";
      font-weight: 400;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
    &__show {
      font-family: "Teko";
      font-weight: 600;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
  }
  &__sold-out {
    position: absolute;
    // bottom: 3rem;
    width: 85%;
    opacity: 0.8;
  }
  &__show {
    width: 90%;
    margin-top: 4.6rem;

    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "Teko";
      font-weight: 700;
      font-size: 1.3rem;
      color: black;
      line-height: 1;
    }
  }
  &__location {
    p {
      font-family: "Teko";
      color: black;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
  &__date-container {
    width: 95%;
    background-color: #848484;
    min-height: 2.5rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Teko";
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  &__buy-btn {
    background-color: #00ffff9c;
    width: 95%;
    height: 3rem;
    color: black;
    font-family: "Teko";
    font-weight: 600;
    font-size: 1.8rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    text-decoration: underline;
    &--sold-out {
      background-color: #b7b7b7;
    }
  }
  &__content-wrapper {
    width: 80%;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: solid 1px aqua;
  }
  &__year-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-bottom: 2px;
    height: 2.5rem;
    p {
      color: black;
      font-family: "Shadow";
      font-weight: 700;
      font-size: 0.8rem;
    }
  }
  &__flag {
    font-size: 2rem;
    position: absolute;
    top: 1rem;
    right: 0.6rem;
  }
}
@media (max-width: 600px) {
  .next-dates-card {
    &__image-data-container {
      width: 25rem;
      height: 40rem;
      // border: solid 1px aqua;
    }
    &__image-wrapper {
      height: 53rem;
    }
    &__next {
      margin-right: 0.2rem;
    }
    &__dates {
      margin-left: 0.2rem;
    }

    &__buy-btn {
      width: 90%;
      height: 5rem;
      font-size: 4rem;
      padding: 4px;
      &--sold-out {
        font-size: 2.8rem;
      }
    }
    &__data-wrapper-front {
      &__location {
        font-size: 3.4rem;
      }
      &__date {
        font-size: 2.7rem;
      }
      &__show {
        font-size: 2.7rem;
        font-weight: 400;
      }
    }

    &__show {
      margin-top: 2rem;
      h3 {
        font-size: 2.2rem;
      }
    }
    &__date-container {
      font-size: 1.4rem;
      min-height: 3rem;
    }
    &__flag {
      font-size: 3rem;
      top: 0rem;
    }
    &__location {
      p {
        font-size: 2.2rem;
        font-weight: 400;
      }
    }
  }
}

@keyframes translateY-50 {
  to {
    transform: translateY(-5rem);
  }
}
