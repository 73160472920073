.select-date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .swiper-pagination {
    margin-bottom: -2rem;
  }
  .select-month-tour {
    position: relative;
    width: 90%;
    overflow: visible;

    &__prev-element {
      position: absolute;
      left: 1.5rem;
      width: 5rem;
      z-index: 2;
      filter: drop-shadow(2px 2px 4px #0000007c);
      img {
        width: 100%;
        transform: rotate(180deg);
      }
    }
    &__next-element {
      position: absolute;
      right: 1.5rem;
      width: 5rem;
      filter: drop-shadow(-2px 2px 4px #0000007c);
      z-index: 2;
      img {
        width: 100%;
      }
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    // color: #0cb1b1e1;
    display: none;
  }
  .swiper-slide,
  swiper-slide {
    max-height: 100%;
    width: auto;
    max-width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .swiper-slide-shadow {
    width: 0;
  }
  &__image {
    z-index: -1;
  }
  &__month-container {
    position: absolute;
    // border: solid 2px aqua;
    width: 80%;
    height: 50%;
    top: 2.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
  }
  &__month {
    font-family: Shadow;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__month-draw {
    position: absolute;
    &--line {
      opacity: 0;
      animation: fadein 0.7s linear forwards;
    }
  }
  &__text {
    text-transform: uppercase;
    position: absolute;
    font-family: Shadow;
    font-weight: 400;
    font-size: 28px;
    color: #000;
    bottom: 13%;
    width: 60%;
    text-align: center;
  }
  &__sunglasses {
    position: absolute;
    right: -7rem;
    top: 0rem;
    width: 25rem;
  }
  &__polaroid-image {
    position: absolute;
    opacity: 0;
    &--show {
      animation: shot 1 0.5s forwards;
    }
    &--0 {
      width: 10vw;
      top: 27%;
      right: 20%;
      animation-delay: 0.1s;
    }
    &--1 {
      width: 10vw;
      bottom: 10%;
      left: 23%;
      z-index: 2;
      filter: drop-shadow(0px 2px 10px #00000062);
      transform: rotate(-3deg);
      animation-delay: 1.5s;
    }
    &--2 {
      width: 13vw;
      top: 40%;
      right: 13%;
      z-index: 3;
      transform: rotate(3deg);
      animation-delay: 2s;
    }
    &--3 {
      width: 8vw;
      top: 20%;
      left: 27%;
      @media (max-width: 820px) {
        .video-popup {
          &__container {
            width: 95%;
          }
        }
      }
      transform: rotate(-5deg);
      animation-delay: 2.5s;
    }
    &--4 {
      width: 11vw;
      bottom: 20%;
      right: 24%;
      z-index: 2;
      filter: drop-shadow(0px 2px 10px #00000088);
      transform: rotate(-5deg);
      animation-delay: 1s;
    }
    &--5 {
      width: 7vw;
      bottom: 35%;
      left: 28%;
      z-index: -1;
      animation-delay: 0.5s;
    }
    // &--6 {
    // }
    // &--easter-egg {
    //   width: 17vw;
    //   bottom: 29%;
    //   left: 12%;
    //   transform: rotate(5deg);
    //   filter: drop-shadow(0px 2px 10px #00000062);
    //   &--shot {
    //     animation: double-shot 1 0.4s ease-in forwards;
    //   }

    //   &:hover {
    //     cursor: pointer;
    //   }
    // }
  }

  &__easter-egg-btn {
    position: absolute;
    width: 17vw;
    bottom: 29%;
    left: 12%;
    z-index: 9;
    animation: floating 8s ease-in-out infinite;
    filter: drop-shadow(0px 2px 10px #ffffff) brightness(1.2);

    &__image {
      width: 100%;
      transform: rotate(5deg);
      transition: all 0.5s;
      opacity: 0;
      &--animate {
        animation: shot 1 1.5s forwards;
        animation-delay: 3.5s;
      }

      &:hover {
        transform: scale(1.1) rotate(-10deg);
        cursor: pointer;
        // filter: drop-shadow(0px 2px 10px #ffffffa4);
      }

      &--zoom {
        transform: scale(20) rotate(0deg);
      }
      &--zoom-out {
        transform: scale(1) rotate(5deg);
      }
    }
    &:hover {
      animation-play-state: paused;
    }
  }
}
@media (max-width: 820px) {
  .select-date {
    .select-month-tour {
      width: 100% !important;
      .swiper-pagination {
        margin-bottom: -3rem;
      }
    }
    &__sunglasses {
      display: none;
    }

    &__polaroid-image {
      position: absolute;
      &--0 {
        width: 20vw;
        top: 45%;
        right: 0%;
        z-index: 1;
      }
      &--1 {
        width: 15vw;
        bottom: 5%;
        left: 5%;
      }
      &--2 {
        display: none;
      }
      &--3 {
        width: 20vw;
        top: 10%;
        left: 0%;
      }
      &--4 {
        width: 22vw;
        bottom: 12%;
        left: 0%;
        z-index: -1;
      }
      &--5 {
        display: none;
      }
      // &--6 {
      // }
      // &--easter-egg {

      // }
    }
    &__easter-egg-btn {
      left: auto;
      width: 21vw;
      bottom: 20%;
      right: 0%;
      z-index: 2;
    }
  }
}
@media (max-width: 600px) {
  .select-date {
    .select-month-tour {
      position: relative;
      width: 100%;
      overflow: visible;
    }
    .swiper-pagination {
      margin-bottom: -3rem;
    }
    &__polaroid-image {
      position: absolute;
      &--0 {
        width: 40vw;
        top: 2%;
        right: 0%;
        z-index: 1;
        transform: rotate(5deg);
      }
      &--1 {
        display: none;
      }
      &--3 {
        width: 40vw;
        top: 70%;
        left: 0%;
        animation-delay: 1s;
      }
      &--4 {
        display: none;
        animation-delay: 2s;
      }
      // &--5 {
      // }
      // &--easter-egg {
      //   left: auto;
      //   width: 31vw;
      //   bottom: 20%;
      //   right: 0%;
      //   z-index: 2;
      // }
    }
    &__easter-egg-btn {
      left: auto;
      width: 35vw;
      bottom: 10%;
      right: 5%;
      z-index: 2;
    }
  }
}
