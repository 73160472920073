.video-popup {
  &--overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000f6;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    animation: fadein 1s 1 forwards;
  }
  &--overlay-close {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  &__container {
    position: relative;
    width: 60vw;
    opacity: 0;
    animation: fadein 1s 1 0.5s forwards;
    // border: solid 1px aqua;
  }
}
@media (max-width: 820px) {
  .video-popup {
    &__container {
      width: 95%;
    }
  }
}
@media (max-width: 600px) {
  .video-popup {
    &__container {
      width: 100%;
    }
  }
}
