.logo_animation {
  width: 100%;
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
  transition: all 1s;
  &--glow {
    filter: drop-shadow(0px 0px 10px #ffff);
  }
  &__logo-out-1 {
    animation: logo-out-1 3s 1.2s 1 forwards;
  }
  &__logo-out-2 {
    animation: logo-out-2 3s 1.2s 1 forwards;
  }
  &__logo-out-3 {
    animation: logo-out-3 3s 1.2s 1 forwards;
  }
  &__logo-out-4 {
    animation: logo-out-4 3s 1.2s 1 forwards;
  }
}
