.bizarrap-tour {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &__cap {
    position: absolute;
    width: 14rem;
    top: 1rem;
    left: -5rem;
    transform: rotate(-80deg);
  }
  &__title-container {
    position: absolute;
    top: 7%;
  }
  &__title-wrapper-relative {
    position: relative;
    // border: solid 1px red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    position: absolute;
    top: 30%;
    font-family: "Shadow";
    color: #000000d7;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2.3vw;
    transform: rotate(-4deg);
  }
  &__title-background {
    width: 22vw;
  }

  &__sunglasses {
    width: 23rem;
    position: absolute;
    top: -4rem;
    right: -5rem;
  }
  &__passport-tour {
    width: 23rem;
    position: absolute;
    bottom: -10rem;
    right: 5rem;
    transform: rotate(-30deg) translateY(100%);
    animation: passport-bzrp-tour 1s 1 forwards;
    z-index: 2;
    filter: drop-shadow(-5px -5px 5px #00000083);
  }
}
@media (max-width: 820px) {
  .bizarrap-tour {
    &__title-container {
      top: 10%;
    }
    &__title-background {
      width: 40vw;
    }
    &__title {
      font-size: 3.7vw;
    }
  }
}
@media (max-width: 600px) {
  .bizarrap-tour {
    &__title-background {
      width: 55vw;
    }
    &__title {
      font-size: 5.5vw;
    }
  }
}
