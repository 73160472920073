.video-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  position: relative;
  // height: 0; /* Establece la altura inicial del contenedor a 0 */
  // padding-bottom: 56.25%;

  &__light-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__light {
    filter: blur(80px) opacity(0.6) saturate(300%);
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: scale(1.2) translateZ(0);
    width: 100%;
    z-index: -1;
    height: 100%;
  }
  &__video {
    width: 100%;
    border-radius: 0.5rem;
  }
}
