.c2022 {
  height: 85vh;
  position: relative;
  display: inline-block;
  transform: rotate(3deg) translate(7rem, -7rem);
  opacity: 0;
  &--animate {
    animation: in2022 1s 1 forwards;
  }
  &__image {
    max-height: 100%;
    width: auto;
    max-width: 100%;
    filter: drop-shadow(-9px -3px 10px #00000086);
  }
  &__calendar {
    position: absolute;
    top: 30%;
    left: 30%;
    transform: translateX(-30%);
    color: black;
    width: 70%;
    button {
      color: black;
    }
  }
}
@media (max-width: 820px) {
  .c2022 {
    height: auto;
    width: 85vw;
    &__calendar {
      top: 30%;
    }
  }
}
@media (max-width: 600px) {
  .c2022 {
    &__image {
      width: 100%;
    }
  }
}

@keyframes in2022 {
  30% {
    opacity: 1;
  }
  to {
    transform: rotate(3deg) translate(0rem, 0rem);
    opacity: 1;
  }
}
