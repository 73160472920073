.tour-carousel {
  display: flex;
  align-items: center;
  transform: rotate(-5deg);
  margin: 0 auto;
  width: 110%;
  height: 100%;
  opacity: 0;
  animation: fadein 1s 1 0.5s forwards;
  position: relative;
  z-index: 2;
  .swiper-slide {
    width: auto;
  }
  .swiper-wrapper {
    transition-timing-function: linear;
  }
  &__background-carousel-wrapper {
    position: absolute;
    // border: solid 1px aqua;
  }
  &__slider {
    // border: solid 1px aqua;
    height: 70% !important;
  }
  &__slide {
    overflow: visible !important;
    flex-shrink: 0;
    transition: 1s;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 25rem !important;
    // border: solid 1px aqua;
    height: 100% !important;
    &--text {
      font-family: Shadow;
      font-style: normal;
      font-weight: 500;
      font-size: 4rem;
    }
  }
  &__mobile {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
}
@media (max-width: 600px) {
  .tour-carousel {
    overflow: visible;

    &__slide {
      width: 80% !important;
      &--text {
        font-size: 6rem;
      }
    }
  }
}
