@import "../../sass/stylesImports.scss";

.error {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  &__logo-wrapper {
    // border: solid 1px orange;
    width: 15%;
    height: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    &--hidden {
      opacity: 1;
      animation: fadeout 2s 1 forwards;
    }
  }
  &__xlr-conector {
    position: absolute;
    width: 30%;
    filter: drop-shadow(0px 15px 7px #00000093);

    &--left {
      left: 0;
      bottom: 0;
    }
    &--right {
      right: 0;
      top: 0;
    }
  }
}
@media (max-width: 820px) {
  .error {
    &__logo-wrapper {
      width: 40%;
    }
    &__xlr-conector {
      width: 45%;
    }
  }
}

@media (max-width: 600px) {
  .error {
    &__logo-wrapper {
      width: 45%;
    }
    &__xlr-conector {
      width: 60%;
    }
  }
}
