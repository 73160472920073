*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  /* Remove safari default styling */
  -webkit-appearance: none;
  user-select: none;
}

/* Render the `main` element consistently in IE. */
main {
  display: block;
}

/* Remove the gray background on active links in IE 10. */
a {
  background-color: transparent;
}

a,
a:visited {
  color: none;
  text-decoration: none;
}

/* Remove the border on images inside links in IE 10. */
img {
  border-style: none;
  user-select: none;
}

/**
 1. Change the font styles in all browsers.
 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  background-color: transparent;
  border: none;
  color: $color-white;
}

/* Remove numeric input controls */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove the inheritance of text transform in Firefox. */
button,
select {
  text-transform: none;
}

/* Correct the inability to style clickable types in iOS and Safari. */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/* Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/* Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/*
 1. Correct the inability to style clickable types in iOS and Safari.
 2. Change font properties to `inherit` in Safari.
*/
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

select,
button {
  cursor: pointer;
}

input {
  caret-color: white;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
button,
a {
  /* Remove touch highlight color */
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
