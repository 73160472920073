// MEDIA QUERY MANAGER

/* 
[0 - 600px]:       Phone
600 - 900px:     Tablet portrait
900 - 1200px:    Tablet landscape
1200 - 1800:   Average Desktop
1800px +:        Big desktop
*/

@mixin respond($breakpoint) {
  @if $breakpoint == mobile-xs {
    @media only screen and (min-width: 200px) {
      @content;
    }
  }
  @if $breakpoint == mobile-s {
    @media only screen and (min-width: 300px) {
      @content;
    }
  }
  @if $breakpoint == mobile-m {
    @media only screen and (min-width: 375px) {
      @content;
    }
  }
  @if $breakpoint == tab-port-s {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == tab-port-m {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media only screen and (min-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 1800px) {
      @content;
    }
  }
}
//DISPLAY FLEX
@mixin flex-config($dis, $direction, $corte, $just, $align) {
  display: $dis;
  flex-flow: $direction $corte;
  justify-content: $just;
  align-items: $align;
}

// CENTERED ABSOLUTE POSITION
/*Mainly for popups*/
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
