.grammy-btn {
  position: fixed;
  top: 0;
  right: -3%;
  z-index: 2;
  transform: translateX(100%);
  filter: drop-shadow(-2px 10px 5px #00000093);
  overflow: visible;
  &--show {
    animation: translateX-Y-0 1s 1 0.5s forwards;
  }
  &__link {
    position: relative;
    display: inline-block;
    transition: all 0.8s;
    color: black;
    &:hover {
      transform: rotate(4deg) scale(1.1);
      filter: drop-shadow(-8px 8px 11px #00000093);
    }
  }
  &__sunglasses {
    width: 28vw;
  }
  &__text {
    text-transform: uppercase;
    position: absolute;
    bottom: 14%;
    left: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2vw;
    font-family: "Shadow";
    color: #000000;
    font-weight: 400;
    text-shadow: 0px 0px 0px #000000;
    // font-weight: 600;
    transform: rotate(1deg);
  }
}

@media (max-width: 820px) {
  .grammy-btn {
    &__sunglasses {
      width: 45vw;
    }
    &__text {
      font-size: 1.9vw;
    }
  }
}

@media (max-width: 600px) {
  .grammy-btn {
    right: -14%;

    &__sunglasses {
      width: 70vw;
    }
    &__text {
      left: 2.5%;
      font-size: 3.16vw;
    }
  }
}
