.grammy {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__credential {
    position: absolute;
    right: 0;
    top: -4rem;
    width: 29vw;
    filter: drop-shadow(-8px 8px 7px #0000006c);
    cursor: pointer;
    transition: all 1s;
    transform: translateY(-10rem);
    opacity: 0;
    &--show {
      animation: translateY-0-opacity-1 1.5s 1s 1 forwards;
    }
    &:hover {
      filter: drop-shadow(0px -8px 20px #fff6d8fa);
    }
    img {
      width: 100%;
    }
  }
  &__recorder {
    position: fixed;
    width: 20vw;
    top: 0;
    right: 30%;
    transition: all 1s;
    transform: translateY(-110%);
    &--show {
      animation: translateY-0 1.5s 1s 1 forwards;
    }

    img {
      width: 100%;
      filter: drop-shadow(-8px 8px 5px #0000007e);
      cursor: pointer;
      transition: all 1s;
      &:hover {
        filter: drop-shadow(0px -8px 20px #fff6d8fa);
      }
    }
  }
  &__1 {
    position: absolute;
    width: 16vw;
    left: -5rem;
    filter: drop-shadow(-8px 8px 7px #0000006c);
    transform: translateX(-100%) rotate(-60deg);
    &--show {
      animation: translateX-0-0deg 1.5s 2s 1 forwards;
    }
  }
  &__2 {
    position: absolute;
    width: 20vw;
    top: -2rem;
    right: 5%;
    filter: drop-shadow(-8px 8px 7px #0000006c);
    transform: translateY(-100%) rotate(60deg);
    &--show {
      animation: translate-0-0-0deg 1.5s 2s 1 forwards;
    }
  }
  &__3 {
    position: absolute;
    width: 17vw;
    bottom: -4rem;
    right: 10%;
    filter: drop-shadow(-8px 8px 7px #0000006c);
    transform: translateY(100%) rotate(60deg);
    z-index: -1;
    &--show {
      animation: translate-0-0-0deg 1.5s 2s 1 forwards;
    }
  }
  &__pappers {
    position: absolute;
    bottom: -11rem;
    left: -8rem;
    width: 55rem;
    filter: drop-shadow(-8px 8px 7px #0000006c);
    opacity: 0;
    animation: fadein 5s 4s 1 forwards;
  }
  &__logo {
    position: absolute;
    top: -6rem;
    left: 15%;
    width: 25vw;
    transform: translateY(-100%);
    animation: sign 5.5s 4s 1 forwards;
    filter: drop-shadow(-8px 8px 7px #0000006c);
    z-index: -1;
  }
  &__polaroid-wrapper {
    // border: solid 1px aqua;
    position: relative;
    width: 42vw;
    height: 30vw;
  }
  &__polaroid-img {
    width: 100%;
    &--1 {
      opacity: 0;
      transform: translateY(100%);
      &--show {
        animation: translate-0-scale-0 1s 1 forwards;
      }
    }
    &--2 {
      opacity: 0;
      transform: translateX(200%);
      &--show {
        animation: translate-0-scale-0 1s 0.2s 1 forwards;
      }
    }
    &--3 {
      opacity: 0;
      transform: translateX(-200%);
      &--show {
        animation: translate-0-scale-0 1s 0.2s 1 forwards;
      }
    }
    &--4 {
      opacity: 0;
      transform: translateY(-100%);
      &--show {
        animation: translate-0-scale-0 1s 0.1s 1 forwards;
      }
    }
  }
  &__polaroid-img-wrapper {
    width: 40%;
    position: absolute;
    filter: drop-shadow(-8px 8px 7px #00000093);
    transition: all 0.5s;
    &:hover {
      cursor: pointer;
    }
    &--1 {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) rotate(-5deg);
      &:hover {
        transform: translateX(-50%) rotate(5deg) scale(1.1);
      }
    }
    &--2 {
      width: 35%;
      right: 5%;
      top: 20%;
      transform: rotate(15deg);
      &:hover {
        transform: translateX(10%) rotate(17deg) scale(1.1);
      }
    }
    &--3 {
      width: 35%;
      left: 10%;
      top: 10%;
      transform: rotate(-17deg);
      &:hover {
        transform: translateX(-10%) rotate(-10deg) scale(1.1);
      }
    }
    &--4 {
      width: 35%;
      left: 53%;
      top: 10%;
      transform: translateX(-40%) rotate(-15deg);
      &:hover {
        transform: translateX(-40%) translateY(-20%) rotate(3deg) scale(1.1);
      }
    }
  }
}

.grammy-video {
  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000ea;
    backdrop-filter: blur(1rem);
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    animation: fadein 0.5s 1 forwards;
  }
  &__overlay-close {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  &__video-container {
    width: 50vw;
    opacity: 0;
    animation: fadein 5s 0.5s 1 forwards;
  }
}
.transition-video-grammy {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, #ffffff00 10rem, #000000ea 23rem);
  top: 0;
  left: 0;
  z-index: 9;
  animation: transition-video-grammy 5s 1 forwards;
}
@media (max-width: 820px) {
  .transition-video-grammy {
    background: radial-gradient(circle, #ffffff00 10rem, #000000ea 35rem);
    animation: transition-video-grammy-tab 5s 1 forwards;
  }
  .grammy {
    &__1 {
      top: 15%;
    }
  }

  .grammy-video {
    &__video-container {
      width: 90vw;
    }
  }
}
@media (max-width: 600px) {
  .grammy {
    &__1 {
      top: 5%;
      width: 36vw;
    }
    &__2 {
      width: 45vw;
      z-index: -1;
      top: auto;
      right: -3rem;
      bottom: 25%;
      transform: translateX(100%) rotate(60deg);
    }
    &__3 {
      width: 37vw;
      right: auto;
      left: 0;
    }
    &__logo {
      display: none;
    }
    &__polaroid-wrapper {
      margin-bottom: 35%;
      width: 100vw;
      height: 80vw;
    }
    &__pappers {
      display: none;
    }
    &__credential {
      top: auto;
      bottom: auto;
      right: auto;
      left: -5rem;
      margin-top: 20%;
      transform: translateY(-10rem);
      opacity: 0;
      width: 42rem;
      animation: translateY-0-opacity-1 1.5s 1s 1 forwards;
    }
    &__recorder {
      width: 60%;
      right: 0;
    }
  }
  .grammy-video {
    &__video-container {
      width: 95vw;
    }
  }
  .transition-video-grammy {
    background: radial-gradient(circle, #ffffff00 10rem, #000000ea 23rem);
    animation: transition-video-grammy-phone 5s 1 forwards;
  }
}
