.bzrp-tour-btn {
  position: relative;
  color: black;
  transform: translateX(80%) translateY(30%) scale(2);
  opacity: 0;
  filter: drop-shadow(-8px 8px 11px #00000013);

  &--show {
    animation: translate-0-scale-0 0.5s 1 forwards;
  }
  &--hidden {
    display: none;
  }
  &__link {
    display: inline-block;
    transition: all 0.8s;
    color: black;
    &:hover {
      transform: rotate(-5.2deg) scale(1.1);
      filter: drop-shadow(-8px 8px 11px #00000093);
    }
  }
  &__image {
    width: 26vw;
  }
  &__text {
    // border: solid 1px aqua;
    position: absolute;
    bottom: 9%;
    right: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8vw;
    font-family: "Shadow";
    color: #000000;
    font-weight: 400;
    text-shadow: 0px 0px 0px #000000;
  }
  &__text-sub {
    position: absolute;
    bottom: 2%;
    right: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3vw;
    font-family: "Shadow";
    font-weight: 400;
    text-shadow: 0px 0px 0px #000000;
    color: #000000;
  }
}
@media (max-width: 820px) {
  .bzrp-tour-btn {
    &__image {
      width: 56vw;
    }
    &__text {
      font-size: 4vw;
    }
    &__text-sub {
      font-size: 2.8vw;
    }
  }
}

@media (max-width: 600px) {
  .bzrp-tour-btn {
    margin-left: 10%;
    &__image {
      width: 59vw;
    }
    &__text {
      font-size: 4.5vw;
    }
    &__text-sub {
      font-size: 3.1vw;
    }
  }
}
