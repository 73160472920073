/* Root style, reference for REM unit */
html {
  // color-scheme: light dark;
  // 1rem setup
  font-size: 62.5%;
  @include respond(mobile-xs) {
    font-size: 40%;
  }
  @include respond(mobile-s) {
    font-size: 50%;
  }
  @include respond(mobile-m) {
    font-size: 55%;
  }
  @include respond(tab-port-s) {
    font-size: 57%;
  }
  @include respond(tab-port-m) {
    font-size: 60%;
  }
  @include respond(tab-land) {
    font-size: 70%;
  }
  @include respond(desktop) {
    font-size: 87.5%;
  }
  @include respond(big-desktop) {
    font-size: 100%;
  }
  touch-action: manipulation;
}

body {
  box-sizing: border-box;
  background-color: black;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100vw;
  background-image: url("../../assets/background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  .swiper-pagination-bullet-active {
    background: #0cb1b1;
    filter: drop-shadow(0px 0px 8px #000);
  }
  .swiper-pagination-bullet {
    background: #0b9999b9;
    filter: drop-shadow(0px 0px 8px #000);
  }
}

* {
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
