@keyframes translateX-Y-0 {
  to {
    transform: translateX(0) translateY(0);
  }
}
@keyframes translateX-110 {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(110%);
  }
}
@keyframes translateX-0-opacity-1 {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes translateY-0-opacity-1 {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes translateY-0 {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes translateY-50-opacity-1 {
  to {
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes opacity-1 {
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes gradient-color {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}
@keyframes translateX-0-0deg {
  to {
    transform: translateX(0) rotate(0deg);
  }
}
@keyframes translateY-0-80deg {
  to {
    transform: rotate(-80deg) translateY(0);
  }
}
@keyframes translate-0-0-0deg {
  to {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
}

//---icons---//
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes translate-0-scale-0 {
  0% {
  }
  5% {
    opacity: 1;
    z-index: 2;
  }
  100% {
    opacity: 1;
    z-index: 2;
    transform: translateY(0) translateX(0) scale(1);
  }
}

//---next dates---//

@keyframes next-dates {
  0% {
    transform: translateY(-2rem) rotate(-5deg);
    opacity: 0;
  }
  2% {
    z-index: 5;
  }
  to {
    transform: translateX(0) translateY(0) rotate(-5deg);
    opacity: 1;
  }
}

//---cap---//

@keyframes cap {
  to {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes next-dates-cap {
  to {
    transform: translateX(0) translateY(0) rotate(-80deg);
    bottom: -10rem;
    left: -5rem;
    z-index: -1;
    width: 27rem;
  }
}
//---sunglasses---//
@keyframes next-dates-sunglasses {
  to {
    top: -5rem;
    right: -8rem;
    width: 27rem;
  }
}
//---bizarrapp--//
@keyframes bizarrapp {
  to {
    transform: translateX(0) rotate(10deg);
    opacity: 1;
  }
}

@keyframes passport {
  to {
    opacity: 1;
    transform: translateX(0) translateY(0) rotate(0) scale(1);
  }
}
@keyframes passport-bzrp-tour {
  to {
    transform: translateY(0) rotate(0) rotate(-30deg);
  }
}
//---logo transitions---//
@keyframes logo-out-1 {
  0% {
    transform: translate(0);
  }
  10% {
    transform: translateY(-10%) translateX(-10%);
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  60% {
    transform: translateY(-12%) translateX(-12%);
    opacity: 1;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  80% {
    transform: translateY(-12%) translateX(-12%);
    opacity: 1;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  100% {
    transform: translateY(-250%) translateX(-250%) scale(4);
    opacity: 0;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
}
@keyframes logo-out-2 {
  0% {
    transform: translate(0);
  }
  10% {
    transform: translateX(10%) translateY(-10%);
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  60% {
    transform: translateX(12%) translateY(-12%);
    opacity: 1;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  80% {
    transform: translateX(12%) translateY(-12%);
    opacity: 1;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  100% {
    transform: translateX(200%) translateY(-200%) scale(4);
    opacity: 0;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
}
@keyframes logo-out-3 {
  0% {
    transform: translate(0);
  }
  10% {
    transform: translateX(10%) translateY(10%);
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  60% {
    transform: translateX(12%) translateY(12%);
    opacity: 1;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  80% {
    transform: translateX(12%) translateY(12%);
    opacity: 1;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  100% {
    transform: translateX(200%) translateY(200%) scale(4);
    opacity: 0;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
}

@keyframes logo-out-4 {
  0% {
    transform: translate(0);
  }
  10% {
    transform: translateX(-10%) translateY(10%);
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  60% {
    transform: translateX(-12%) translateY(12%);
    opacity: 1;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  80% {
    transform: translateX(-12%) translateY(12%);
    opacity: 1;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
  100% {
    transform: translateX(-200%) translateY(200%) scale(4);
    opacity: 0;
    filter: drop-shadow(0px 0px 20px #ffff);
  }
}
@keyframes shot {
  from {
    filter: brightness(1000%);
    opacity: 0;
  }
  40% {
    filter: brightness(1000%);
    opacity: 1;
  }
  to {
    filter: brightness(100%);
    opacity: 1;
  }
}
@keyframes double-shot {
  0% {
    filter: brightness(1000%);
  }
  5% {
    filter: brightness(100%);
  }
  7% {
    filter: brightness(800%);
  }
  20% {
    filter: brightness(100%);
  }
  // 50% {
  //   filter: brightness(100%);
  // }
  // 90% {
  //   filter: brightness(500%);
  // }
  100% {
    filter: brightness(100%);
  }
}
@keyframes sign {
  20% {
    transform: translateY(0);
  }
  79% {
    transform: rotate(0) translateY(0);
    transform-origin: top right;
  }
  80% {
    transform: rotate(-10deg) translateY(0);
    transform-origin: top right;
  }
  82% {
    transform: rotate(-8deg) translateY(0);
    transform-origin: top right;
  }
  84% {
    transform: rotate(-10deg) translateY(0);
    transform-origin: top right;
  }
  100% {
    transform: rotate(-10deg) translateY(0);
    transform-origin: top right;
  }
}

//transition grammy video

@keyframes transition-video-grammy {
  0% {
    background-position: -100% -100%;
    background-size: 200% 200%;
  }
  20% {
    background-position: 50% 50%;
    background-size: 200% 200%;
  }
  30% {
    background-position: 50% 50%;
    background-size: 200% 200%;
  }
  40% {
    background-position: 100% 50%;
    background-size: 200% 200%;
  }
  50% {
    background-position: 100% 50%;
    background-size: 200% 200%;
  }
  60% {
    background-position: 10% 58%;
    background-size: 200% 200%;
  }
  100% {
    background-position: 10% 58%;
    background-size: 200% 200%;
  }
}

@keyframes transition-video-grammy-tab {
  0% {
    background-position: -100% -100%;
    background-size: 200% 200%;
  }
  20% {
    background-position: 100% 80%;
    background-size: 200% 200%;
  }
  30% {
    background-position: 100% 80%;
    background-size: 200% 200%;
  }
  40% {
    background-position: 50% 50%;

    background-size: 200% 200%;
  }
  50% {
    background-position: 50% 50%;
    background-size: 200% 200%;
  }
  60% {
    background-position: 10% 85%;
    background-size: 200% 200%;
  }
  100% {
    background-position: 10% 85%;
    background-size: 200% 200%;
  }
}

@keyframes transition-video-grammy-phone {
  0% {
    background-position: -100% -100%;
    background-size: 200% 200%;
  }
  20% {
    background-position: 50% 60%;
    background-size: 200% 200%;
  }
  30% {
    background-position: 50% 60%;
    background-size: 200% 200%;
  }
  40% {
    background-position: 25% 95%;
    background-size: 200% 200%;
  }
  50% {
    background-position: 25% 95%;
    background-size: 200% 200%;
  }
  60% {
    background-position: 50% 30%;
    background-size: 200% 200%;
  }
  100% {
    background-position: 50% 30%;
    background-size: 200% 200%;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1) rotate(5deg);
  }
  50% {
    transform: scale(1.1) rotate(-2deg);
    // filter: drop-shadow(0px 2px 10px #ffffffa4);
  }
  100% {
    transform: scale(1) rotate(5deg);
  }
}
@keyframes floating {
  0%,
  100% {
    transform: translateY(1.5rem) rotate(5deg); /* Posición inicial y final */
  }
  50% {
    transform: translateY(-1.5rem) rotate(5deg); /* Posición máxima de subida */
  }
}
