.tour-card {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Shadow;
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.568));

    &--image {
      width: 20rem;
    }
  }
}
@media (max-width: 600px) {
  .tour-card {
    &__item {
      font-size: 3rem;
      &--image {
        width: 35rem;
      }
    }
  }
}
