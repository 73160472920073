.countries-popup {
  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(14, 13, 13, 0.7);
    backdrop-filter: blur(1rem);
    top: 0;
    left: 0;
    z-index: 8;
  }
  &__overlay-close {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
  }
  &__container {
    // border: solid 1px aqua;
    display: flex;
    // flex-direction: column;
    align-items: center;
    height: 80%;
    width: 90%;
    gap: 1rem;
    // justify-content: center;
  }
  //   &__title-container {
  //     // border: solid 1px aqua;
  //     position: relative;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }
  &__title {
    position: absolute;
    bottom: 47%;
    font-family: Shadow;
    font-weight: 400;
    font-size: 3rem;
    color: black;
    transform: rotate(-4deg);
  }
  &__polaroid-cap {
    height: 90%;
    transform: rotate(2deg);
    opacity: 0;
    &--show {
      animation: shot 1 1s forwards;
    }
  }
  &__flag-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    z-index: 2;
    width: 100%;
    justify-items: center;
    a:last-child {
      grid-column: span 3; /* Esto hará que el último elemento ocupe ambas columnas */
      width: 33%;
    }
  }

  &__papper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    &--rotate {
      transform: rotateZ(180deg) translateY(1rem);
    }
  }
  //   &__flag {
  //     border-radius: 0.5rem;
  //   }
  &__link {
    transition: all 0.4s;
    // border: solid 1px orange;
    width: 100%;
    height: 8rem;
    font-size: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      transform: scale(1.1);
    }
    &:visited {
      text-decoration: none;
    }
    &--text {
      transform: rotate(-5deg);
      margin-bottom: 1rem;
      font-family: Shadow;
      color: black;
    }
    &--icon-container {
      transform: rotate(-5deg);
      width: 2vw;
      margin-right: 0.5rem;
    }
  }
  &__close {
    position: absolute;
    font-family: Shadow;
    font-size: 4rem;
    top: 2rem;
    right: 2rem;
    width: 5rem;
    height: 5rem;
  }
}
@media (max-width: 820px) {
  .countries-popup {
    // &__overlay {
    //   position: fixed;
    //   width: 100vw;
    //   height: 100vh;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   background-color: rgba(14, 13, 13, 0.7);
    //   backdrop-filter: blur(1rem);
    //   top: 0;
    //   left: 0;
    //   z-index: 8;
    // }
    &__container {
      flex-direction: column;
      padding-top: 10%;

      // justify-content: flex-start;
      // padding-top: 10%;
      // padding-bottom: 2rem;
      // width: 100%;
      // height: 100%;
      // overflow-y: scroll;
    }
    &__polaroid-cap {
      height: auto;
      width: 70%;
    }
    &__link {
      height: 11rem;
      font-size: 3rem;
      &--icon-container {
        width: 3vw;
      }
    }
    &__overlay {
      align-items: flex-start;
      overflow: hidden;
    }
    &__container {
      justify-content: center;
    }

    &__flag-wrapper {
      grid-template-columns: repeat(2, 1fr);
      gap: 0rem;
      a:last-child {
        grid-column: span 2; /* Esto hará que el último elemento ocupe ambas columnas */
        width: 50%;
      }
    }
    &__title {
      font-size: 4rem;
    }
  }
}
@media (max-width: 600px) {
  .countries-popup {
    &__container {
      justify-content: flex-start;
      padding-top: 10%;
      padding-bottom: 2rem;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
    &__title {
      bottom: 45%;
    }
    &__link {
      font-size: 2rem;
      height: 7.5rem;
      &--icon-container {
        width: 4vw;
      }
    }
    &__papper {
      width: 100%;
    }
    &__flag-wrapper {
      width: 95%;
      grid-template-columns: repeat(2, 1fr);
      gap: 0rem;
      a:last-child {
        grid-column: span 2; /* Esto hará que el último elemento ocupe ambas columnas */
        width: 50%;
      }
    }
    &__flag {
      width: 100%;
    }
    &__close {
      top: 1rem;
      right: 1rem;
    }
  }
}
// @media (max-width: 400px) {
//   .countries-popup {
//     &__image {
//       width: 50%;
//     }
//   }
// }
