.audioplayer {
  background: #c6c6c6;
  width: 30rem;
  min-height: 15rem;
  border: solid 2px rgb(71, 71, 71);
  border-top: solid 2px rgb(235, 235, 235);
  border-left: solid 2px rgb(235, 235, 235);
  border-bottom: solid 2px rgb(94, 94, 94);
  border-right: solid 2px rgb(94, 94, 94);
  padding: 5px;
  &__top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40%;
  }
  &__screen {
    border-top: solid 2px rgb(94, 94, 94);
    border-left: solid 2px rgb(94, 94, 94);
    border-bottom: solid 2px rgb(235, 235, 235);
    border-right: solid 2px rgb(235, 235, 235);
    background: black;
    width: 80%;
    height: 100%;
    display: flex;
    color: #2edf31;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
  }
  &__track-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-right: 1rem;
    margin-left: 0.7rem;
  }
  &__counter-label {
    display: flex;
    gap: 2.1rem;
  }
  &__counter {
    color: #2edf31;
    font-size: 3rem;
    font-family: "Digital-Dismay";
    line-height: 110%;
  }
  &__counter-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  &__controls-container {
    // border: solid 1px aqua;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__controls-wrapper {
    display: flex;
    width: 80%;
    height: 3rem;
  }
  &__status-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  &__status {
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &--tick {
      height: 1rem;
    }
  }
  &__mode {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__mode-data {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  &__spacer-wrapper {
    margin: 1rem 0;
  }
  &__line-space {
    height: 2px;
    &--dark {
      background: rgb(153, 153, 153);
    }
    &--light {
      background: rgb(235, 235, 235);
    }
  }
  &__spacer-wrapper-bottom {
    margin-top: 1rem;
    margin-bottom: 0.4rem;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    width: 17%;
    height: 100%;
    // background: #c6c6c6;

    background: #f6f6f6;
    background: -moz-linear-gradient(
      top,
      #f6f6f6 0%,
      #f6f6f6 0%,
      #c6c6c6 11%,
      #c6c6c6 85%,
      #868686 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, #f6f6f6),
      color-stop(0%, #f6f6f6),
      color-stop(11%, #c6c6c6),
      color-stop(85%, #c6c6c6),
      color-stop(100%, #868686)
    );
    background: -webkit-linear-gradient(
      top,
      #f6f6f6 0%,
      #f6f6f6 0%,
      #c6c6c6 11%,
      #c6c6c6 85%,
      #868686 100%
    );
    background: -o-linear-gradient(
      top,
      #f6f6f6 0%,
      #f6f6f6 0%,
      #c6c6c6 11%,
      #c6c6c6 85%,
      #868686 100%
    );
    background: -ms-linear-gradient(
      top,
      #f6f6f6 0%,
      #f6f6f6 0%,
      #c6c6c6 11%,
      #c6c6c6 85%,
      #868686 100%
    );
    background: linear-gradient(
      to bottom,
      #f6f6f6 0%,
      #f6f6f6 0%,
      #c6c6c6 11%,
      #c6c6c6 85%,
      #868686 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#868686', GradientType=0 );
    border-top: solid 2px rgb(151, 151, 151);
    border-left: solid 1px rgb(151, 151, 151);
    border-bottom: solid 2px rgb(94, 94, 94);
    border-right: solid 2px rgb(94, 94, 94);
    &--right {
      margin-right: 1rem;
      margin-left: 1rem;

      height: 3rem;
    }
    &--upload {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 1.4rem;
      height: 1.4rem;
      //   border: solid 1px aqua;
    }
    &--line {
      width: 94%;
      height: 0.2rem;
      background: black;
      margin-top: -0.32rem;
    }
  }
  &__btn:active {
    border-top: solid 2px rgb(41, 41, 41);
    border-left: solid 2px rgb(41, 41, 41);
    border-bottom: solid 2px rgb(235, 235, 235);
    border-right: solid 2px rgb(235, 235, 235);
    background: #6e6e6e;
  }
  &__timeline-wrapper {
    width: 100%;
  }

  &__timeline {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    border-top: solid 2px rgb(94, 94, 94);
    border-left: solid 2px rgb(94, 94, 94);
    border-bottom: solid 2px rgb(235, 235, 235);
    border-right: solid 2px rgb(235, 235, 235);
    // border: solid 1px aqua;
    width: 100%;
    overflow: hidden;
    &::-webkit-slider-runnable-track {
      height: 15px;
      background: rgb(141, 141, 141);
    }
    &::-moz-range-track {
      height: 15px;
      background: rgb(141, 141, 141);
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 100%;
      width: 10px;
      border: none;
      background-color: #0100ff;
      box-shadow: -407px 0 0 400px #0100ff;
    }
    &::-moz-range-thumb {
      height: 100%;
      width: 10px;
      border: none;
      background-color: #0100ff;
      box-shadow: -407px 0 0 400px #0100ff;
    }
  }
}
@media (max-width: 600px) {
  .audioplayer {
    width: 90vw;
    height: 20rem;
    &__counter {
      font-size: 5rem;
    }
    &__status {
      width: 3rem;
      height: 3rem;
      &--tick {
        height: 2rem;
      }
    }
  }
}
