.audioplayerpopup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(14, 13, 13, 0.7);
  backdrop-filter: blur(1rem);
  top: 0;
  left: 0;
  z-index: 8;
  &__popup {
    position: relative;
    // border: solid 1px aqua;
    z-index: 99;

    &__cancel {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      color: black;
      z-index: 2;
      border-top: solid 2px rgb(235, 235, 235);
      border-left: solid 2px rgb(235, 235, 235);
      border-bottom: solid 2px rgb(94, 94, 94);
      border-right: solid 2px rgb(94, 94, 94);
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2rem;
      &:active {
        background: rgb(143, 143, 143);
        border-top: solid 2px rgb(94, 94, 94);
        border-left: solid 2px rgb(94, 94, 94);
        border-bottom: solid 2px rgb(235, 235, 235);
        border-right: solid 2px rgb(235, 235, 235);
      }
    }
  }
}
.audioplayerpopup-overlay {
  // border: solid 1px aqua;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 8;
}
