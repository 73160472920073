.live-tour {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-image: url("../../../../assets/LiveTour/background-live-tour.webp");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 9;
  overflow-y: scroll;
  scroll-behavior: smooth;
  &__close {
    width: 3rem;
    height: 3rem;
    position: fixed;
    right: 2rem;
    top: 2rem;
    transition: all 0.5s;
    &:hover {
      transform: scale(1.1);
    }
  }
  &__logo-wrapper {
    position: fixed;
    left: 45.2vw;
    top: 4rem;
    width: 32.5rem;
    z-index: -1;
    overflow: visible;
  }
  &__logo-img {
    width: 100%;
    filter: brightness(70%);
    &--animation-initial {
      animation: flicker-initial 2s 2s 1 forwards;
    }
    &--animation-loop {
      animation: flicker-loop 9s infinite;
    }
  }
  &__live-tour-2024 {
    margin-top: 1.5rem;
  }
  // &__logo {
  //   font-family: Changeling;
  //   text-transform: uppercase;
  //   font-size: 3.35rem;
  //   margin-bottom: 2rem;
  //   white-space: nowrap;
  // }
  &__dates-container {
    position: relative;
    margin-top: 17rem;
    margin-left: 45vw;
    width: 33rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1.8rem;
    padding-bottom: 4rem;
    list-style: none;
    padding-bottom: 10rem;
  }
  &__loader-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 4rem;
  }
}

@media (max-width: 820px) {
  .live-tour {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo-wrapper {
      left: 50%;
      transform: translateX(-50%);
    }
    &__dates-container {
      margin-left: 0;
    }
  }
}
@media (max-width: 600px) {
  .live-tour {
    // background-position-x: 20%;
    background-image: url("../../../../assets/LiveTour/background-live-tour-mobile.webp");
    // background-size: 100%;

    &__logo-wrapper {
      margin-top: 10%;
      width: 79%;
    }
    // &__logo {
    //   font-size: 9.2vw;
    // }
    &__dates-container {
      margin-top: 51%;
      margin-left: 0;
      width: 84%;
      padding-bottom: 15rem;
    }
  }
}

@keyframes flicker-initial {
  0% {
    filter: brightness(70%);
  }
  10% {
    filter: brightness(70%);
  }
  12% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  12.1% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }

  20% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  20.1% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  20.6% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  30% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff);
  }
  30.1% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  30.5% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  30.6% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  45% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  45.1% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  50% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  55% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  55.1% {
    filter: brightness(100%);
  }
  57% {
    filter: brightness(100%);
  }
  57.1% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  60% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  60.1% {
    filter: brightness(100%);
  }
  65% {
    filter: brightness(100%);
  }
  65.1% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  75% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  75.1% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  77% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  77.1% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  85% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  85.1% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  86% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  86.1% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  100% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
}

@keyframes flicker-loop {
  0% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  50% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  50.1% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  50.5% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  89% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  90% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  90.1% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  90.5% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  91% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  91.1% {
    filter: brightness(100%) drop-shadow(0px 0px 3px #0066ff);
  }
  91.5% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
  100% {
    filter: brightness(120%) drop-shadow(0px 0px 3px #0066ff)
      drop-shadow(0px 0px 8px #0066ff) drop-shadow(0px 0px 3px #0066ff);
  }
}
