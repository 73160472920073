.polaroid-popup {
  &--overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(14, 13, 13, 0.7);
    backdrop-filter: blur(1rem);
    top: 0;
    left: 0;
    z-index: 9;
  }
  &--overlay-close {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  &--container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__background {
    width: 40rem;
  }
  &__city {
    font-family: Shadow;
    font-size: 2.5rem;
    color: rgba(0, 0, 0, 0.863);
    z-index: 2;
  }
  &__date-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    // border: #00fbff solid 1px;
  }
  &__date {
    font-family: Shadow;
    font-weight: 400;
    font-size: 1.6rem;
    color: black;
  }
  &__close {
    font-family: Shadow;
    color: #000000c9;
    font-size: 3rem;
    position: absolute;
    right: 5rem;
    top: 2rem;
    transform: rotate(-8deg);
  }
  &__data-wrapper {
    // border: #00fbff solid 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
  }
  &__data-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    transform: rotate(-4deg);
    top: 6rem;
    // border: #00fbff solid 1px;
  }
  &__video {
    width: 50%;
    z-index: 2;
    transform: translateY(1.5rem);
    // border: solid 1px aqua;
    .swiper-pagination {
      position: initial;
      // border: solid 1px aqua;
      margin-top: 0.5rem;
      // height: 5rem;
    }
    .swiper-wrapper {
      height: auto !important;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      color: #0cb1b1e1;
      font-size: 2.5rem;
    }
  }
  &__image-polaroid {
    position: absolute;
    transition: all 0.5s;
    filter: drop-shadow(-8px 8px 12px #00000050);

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
    &--0 {
      left: 20%;
      bottom: 3%;
      transform: rotate(2deg);
    }
    &--1 {
      left: 10%;
      top: 5%;
      transform: rotate(-3deg);
    }
    &--2 {
      right: 10%;
      top: 5%;
      transform: rotate(3deg);
    }
    &--3 {
      bottom: -2%;
      transform: rotate(1deg);
    }
    &--4 {
      top: 2%;
      transform: rotate(-3deg);
    }
    &--5 {
      right: 10%;
      bottom: 5%;
      transform: rotate(1deg);
    }
    &--6 {
      left: -2%;
      bottom: 20%;
    }
    &--7 {
      right: -2%;
    }
  }
}
.image-popup {
  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(14, 13, 13, 0.404);
    backdrop-filter: blur(1rem);
    top: 0;
    left: 0;
    z-index: 99;
    opacity: 0;
    animation: fadein 0.7s 1 forwards;
  }
  &__swiper-image {
    width: 35% !important;
    overflow: visible !important;
  }
  &__overlay-close {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  &__image {
    width: 100%;
  }
  &__image-wrapper {
    position: relative;
    z-index: 2;
  }
  &__close {
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: 0;
    top: 0;
    font-family: Shadow;
    color: rgba(0, 0, 0, 0.788);
    font-size: 2rem;
    font-weight: 700;
  }
}

@media (max-width: 820px) {
  .polaroid-popup {
    &--container {
      flex-direction: column-reverse;
    }
    &__video {
      width: 95%;
      transform: none;
      overflow-y: visible;
      // margin-bottom: 40%;
    }
  }
}
@media (max-width: 600px) {
  .polaroid-popup {
    &--container {
      flex-direction: column-reverse;
    }
    &__video {
      width: 95%;
      margin-bottom: 40%;
    }

    &__image-polaroid {
      &--0 {
        left: -6%;
        bottom: 12%;
      }
      &--1 {
        left: -3%;
        top: 20%;
        z-index: 2;
      }
      &--2 {
        right: -6%;
        top: 14%;
      }
      &--3 {
        bottom: 2%;
        z-index: 2;
      }
      &--4 {
        top: 2%;
      }
      &--5 {
        right: -6%;
        bottom: 15%;
      }
      &--6 {
        left: -10%;
        top: -1%;
      }
      &--7 {
        right: -5%;
        bottom: -4%;
      }
    }
  }
  .image-popup {
    &__swiper-image {
      width: 85% !important;
      overflow: visible !important;
    }
  }
}
